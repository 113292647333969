<template>
  <div class="article-LR" :style="articleContainStyl" ref="articleContainer">
    <div class="more-box-top" :style="linkTopStyl">
      <a href="javascript:;" class="link-more" @click="checkMore">
        查看更多>>
      </a>
    </div>
    <div class="article-inner">
      <div
        class="article-box"
        :style="articleBoxStyl"
        v-for="(item, index) in 4"
        :key="index"
        @click="skipLinks"
      >
        <div class="img" :style="imageStyl">
          <!-- <img src="../../../../../../../Desktop/11.png" alt="" /> -->
        </div>
        <div class="article">
          <div
            class="main-title"
            :style="mainTitle"
            v-if="receiveProps.titleStatus"
          >
            {{ "我是主标题" }}
          </div>
          <div
            class="description"
            :style="descriptionTitle"
            v-if="receiveProps.descStatus"
          >
            {{
              "文章摘要文章摘要文章摘要文章摘要文章摘要文章摘要文章摘文章摘要文章摘要文章摘要文章摘要文章摘要文章摘要文章摘文章摘要文章摘要文章摘要文章摘要文章摘要文章摘要文章摘文章摘要文章摘要文章摘要文章摘要文章摘要文章摘要文章摘要文章摘要文章摘要文章摘要文章摘文章摘要文章摘要文章摘要文章摘要文章摘要文章摘要文章摘文章摘要文章摘要文章摘要文章摘要文章——最多三行字"
            }}
          </div>
          <p>
            <span class="publish-time">{{ "2020-02-10" }}</span>
            <span class="obser-num">我是大眼睛 2333</span>
          </p>
        </div>
      </div>
    </div>
    <div class="swiper-wrap">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in imgList"
            :key="index"
          >
            <div class="inner-swiper">
              <div class="image-swiper">
                <img :src="item.imgUrl" class="imgUrl" alt="" />
              </div>
              <div class="article">
                <div class="main-title" :style="mainTitle">
                  {{ "我是主标题" }}
                </div>
                <div class="description" :style="descriptionTitle">
                  {{
                    "文章摘要文章摘要文章摘要文章摘要文章摘要文章摘要文章摘文章摘要文章摘要文章摘要文章摘要文章摘要文章摘要文章摘文章摘要文章摘要文章摘要文章摘要文章摘要文章摘要文章摘文章摘要文章摘要文章摘要文章摘要文章摘要文章摘要文章摘要文章摘要文章摘要文章摘要文章摘文章摘要文章摘要文章摘要文章摘要文章摘要文章摘要文章摘文章摘要文章摘要文章摘要文章摘要文章——最多三行字"
                  }}
                </div>
                <p>
                  <span class="publish-time">{{ "2020-02-10" }}</span>
                  <span class="obser-num">我是大眼睛 2333</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="swiper-pagination"></div> -->
      </div>
      <!-- Add Arrows -->
      <div class="button-box _clear">
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
    <div class="more-box-bottom" :style="linkBottomStyl">
      <a href="javascript:;" class="link-more-bottom"> 查看更多>> </a>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
export default {
  name: "articleLR",
  props: {
    receiveProps: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    articleContainStyl() {
      return {
        "--all-margin-top": "20px",
        "--all-margin-bottom": "20px",
        "--all-background-color": "red",
        // "--all-margin-left": "20px",
        // "--all-margin-right": "20px",
        // "--all-border-width": "3px",
        // "--all-box-shadow": "",
        // "--all-border-radius": "2px",
      };
    },
    linkTopStyl() {
      let { linkBtnLocation, linkBtnAlign } = this.receiveProps;
      return {
        "--link-text-align": linkBtnAlign,
        "--link-top-display": linkBtnLocation == "top" ? "" : "none",
      };
    },
    linkBottomStyl() {
      let { linkBtnLocation, linkBtnAlign } = this.receiveProps;
      return {
        "--link-text-align": linkBtnAlign,
        "--link-bottom-display": linkBtnLocation == "top" ? "none" : "",
      };
    },
    mainTitle() {
      let { titleAlign, titleBold, titleColor, titleSize } = this.receiveProps;

      return {
        "--main-font-color": titleColor,
        "--main-font-size": titleSize,
        "--main-font-weight": titleBold ? "700" : "400",
        "--main-text-align": titleAlign,
      };
    },
    descriptionTitle() {
      let { descSize, descColor, descBold, descAlign } = this.receiveProps;
      return {
        "--desp-font-color": descColor,
        "--desp-font-size": descSize,
        "--desp-font-weight": descBold ? "700" : "400",
        "--desp-text-align": descAlign,
        "--desp-line-clamp": this.lineNum == 3 ? 1 : this.lineNum == 1 ? 3 : 2,
      };
    },
    imageStyl() {
      let {
        leftRightGraphic,
        entryNumOrigin,
        imgBackgroundColor,
        imgBorderColor,
      } = this.receiveProps;
      return {
        "--image-border-width": "3px",
        "--image-border-color": imgBorderColor,
        "--image-border-radius": "20px",
        "--image-box-shadow": "",
        "--image-background-color": imgBackgroundColor,
        "--image-width": entryNumOrigin == 3 ? "120px" : "210px",
        "--image-height": entryNumOrigin == 3 ? "90px" : "158px",
        "--image-change-float": leftRightGraphic == 1 ? "left" : "right", // 判断图片在左还是在右     --article-box-display
      };
    },
    articleBoxStyl() {
      // 判断上下图文   还是左图右文
      let { leftRightGraphic, entryNumOrigin } = this.receiveProps;
      return {
        "--article-box-width": `calc(${100 / entryNumOrigin}% - 48px)`,
        "--article-box-flex-direction": entryNumOrigin == 4 ? "column" : "row",
        "--article-box-display": leftRightGraphic ? "" : "flex", // 左右图文时-->置空  上下图文时-->flex
        "--article-box-border-width": "3px",
        "--article-box-background-color": "red",
      };
    },
  },
  data() {
    return {
      lineNum: 3,
      imgList: [
        {
          imgUrl:
            "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/aic/banner1.jpg",
        },
        {
          imgUrl:
            "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/aic/banner2.jpg",
        },
        {
          imgUrl:
            "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/aic/banner1.jpg",
        },
        {
          imgUrl:
            "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/aic/banner2.jpg",
        },
      ],
    };
  },
  created() {
    this.$nextTick(() => {
      this.initSwiper();
    });
  },
  mounted() {},
  methods: {
    initSwiper() {
      this.swiper = new Swiper(".swiper-container", {
        slidesPerView: 6,
        spaceBetween: 24,
        autoplay: true,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    skipLinks() {
      console.log("------>");
    },
    checkMore() {
      let {openModel,entryUrl,entryType,articleId,articleClassId,} = this.receiveProps.buttonData;
      let skipUrl =
        entryType != 1 && entryType != 5
          ? entryUrl :entryType==1?
           this.$router.resolve({
              path:  '/cms/detail',
              query: {
                articleId: articleId
              },
            }): this.$router.resolve({
              path:  '/cms/list',
              query: {
                classId: articleClassId,
                specialId: ''
              },
            });
            let routerUrl = typeof(skipUrl) == 'object'? skipUrl.href: skipUrl
      if (openModel == 1) {
        window.location.href = routerUrl; // 路由跳转需要结构出href
        return;
      }
      window.open(routerUrl);
      
    },
  },
};
</script>

<style lang="less" scoped>
.article-LR {
  width: 1200px;
  margin: 0 auto;
  margin-top: var(--all-margin-top);
  margin-bottom: var(--all-margin-bottom);
  background-color: var(--all-background-color);
  //   margin-left: var(--all-margin-left);
  //   margin-right: var(--all-margin-right);
  //   border-width: var(--all-border-width);
  //   border-radius: var(--all-border-radius);
  .more-box-top {
    width: 100%;
    padding: 25px 24px 0;
    text-align: var(--link-text-align);
    display: var(--link-top-display);
  }
  .more-box-bottom {
    display: var(--link-bottom-display);
    width: 100%;
    padding: 0 24px 25px;
    text-align: var(--link-text-align);
  }
  .article-inner {
    margin: 24px;
    display: flex;
    flex-wrap: wrap;
    .article-box {
      display: "";
      width: var(--article-box-width);
      flex-direction: var(--article-box-flex-direction);
      border-width: var(--article-box-border-width);
      background-color: var(--article-box-background-color);
      //   border-color: #000;
      padding: 24px;
      .img {
        width: var(--image-width);
        height: var(--image-height);
        margin-right: 16px;
        border-width: var(--image-border-width);
        border-color: var(--image-border-color);
        border-radius: var(--image-border-radius);
        box-shadow: var(--image-box-shadow);
        background-color: var(--image-background-color);
        float: left;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .article {
        flex: 1;
        .main-title {
          color: var(--main-font-color);
          font-size: var(--main-font-size);
          font-weight: var(--main-font-weight);
          text-align: var(--main-text-align);
          margin-bottom: 8px;
        }
        .description {
          color: var(--desp-font-color);
          font-size: var(--desp-font-size);
          font-weight: var(--desp-font-weight);
          text-align: var(--desp-text-align);
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: var(--desp-line-clamp);
          overflow: hidden;
          margin-bottom: 8px;
        }
        .publish-time {
          width: 81px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 20px;
          margin-right: 24px;
        }
        .obser-num {
          width: 34px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
        }
      }
    }
  }
  .swiper-wrap {
    // display: none;
    position: relative;
    margin: 24px;
    .swiper-slide {
      .inner-swiper {
        .image-swiper {
          width: 172px;
          height: 129px;
        }
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .button-box {
      .swiper-button-next {
        position: absolute;
        top: 37%;
        right: 0;
      }
      .swiper-button-prev {
        position: absolute;
        top: 37%;
        left: 0;
      }
    }
  }
  // 公用样式
  .article {
    flex: 1;
    .main-title {
      color: var(--main-font-color);
      font-size: var(--main-font-size);
      font-weight: var(--main-font-weight);
      text-align: var(--main-text-align);
      margin-bottom: 8px;
    }
    .description {
      color: var(--desp-font-color);
      font-size: var(--desp-font-size);
      font-weight: var(--desp-font-weight);
      text-align: var(--desp-text-align);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: var(--desp-line-clamp);
      overflow: hidden;
      margin-bottom: 8px;
    }
    .publish-time {
      width: 81px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      margin-right: 24px;
    }
    .obser-num {
      width: 34px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }
  }
}
</style>